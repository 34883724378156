import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, Paper } from '@mui/material';
import { jobs } from '../db-files/test-jobs';
import JobPost from '../JobsPage/JobPost';
import useScreenResolution from '../hooks/useScreenResolution';
import { subscriptionPage, savedJobsPage, settingsPage, exitPage } from '../Home/menuPages';
import { navigate } from 'gatsby';
import PaymentIcon from '@mui/icons-material/Payment';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}

const JobsPostsList = (props) => {
    return (
        <Grid container sx={{ margin: '24px auto', }} gap={2}>
            {
                jobs.map(job => (
                    <JobPost key={job.id} job={job} />
                ))
            }
        </Grid>
    )
};

const AdditionalProfilePages = () => {
    const userProfilePages = [subscriptionPage, savedJobsPage, settingsPage, exitPage];

    const muiIcons = {
        'Ценови лист': <PaymentIcon sx={{ color: 'primary.main' }} />,
        'Запазени обяви': <FolderSpecialIcon sx={{ color: 'primary.main' }} />,
        'Настройки': <SettingsIcon sx={{ color: 'primary.main' }} />,
        'Изход': <LogoutIcon sx={{ color: 'primary.main' }} />,
    };

    return (
        <>
            {
                userProfilePages.map(p => {
                    return (
                        <Paper
                            key={p.title}
                            onClick={() => navigate(p.path)}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                                backgroundColor: 'white',
                                padding: 2,
                                margin: 1,
                                borderRadius: '5px',
                                '&:hover': {
                                    backgroundColor: '#f6f8fa',
                                }
                            }}
                        >
                            {muiIcons[p.title]}
                            <Typography>{p.title}</Typography>
                        </Paper>
                    )
                })
            }
        </>
    )
};

export default function ProfileTabs() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => { setValue(newValue) };

    const isBelowLargeResolution = useScreenResolution('lg');

    return (
        <>
            {
                isBelowLargeResolution
                    ? <Box sx={{ width: '100%', marginTop: 2 }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label='Обяви' id={0} />
                                <Tab label='Настройки' id={1} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <JobsPostsList />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <AdditionalProfilePages />
                        </TabPanel>
                    </Box>
                    : <JobsPostsList />
            }
        </>
    );
};