import * as React from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from "@mui/material";

export default function EditButton(props) {
    const { handleOpenEditDialog, editStyle } = props;

    return (
        <IconButton
            sx={{
                ...editStyle,
                backgroundColor: 'primary.main',
            }}
            disableFocusRipple
            disableRipple
            onClick={handleOpenEditDialog}
        >
            <EditOutlinedIcon sx={{ color: 'white' }} />
        </IconButton>
    )
};