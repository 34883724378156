import * as React from "react";
import {
    Avatar, Box, Button, Card, Dialog, DialogActions,
    DialogContent, DialogTitle, IconButton, Stack, Typography
} from '@mui/material';
import currentUser from "../components/db-files/currentUser";
import MainContentStructure from '../components/NavigationBar/MainContentStructure';
import ProfileTabs from "../components/ProfilePage/ProfileTabs";
import EditButton from "../components/ProfilePage/EditButton";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ImageUpload from "../components/RegisterPage/ImageUpload";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import useScreenResolution from "../components/hooks/useScreenResolution";
import ProfileSettings from "../components/ProfilePage/ProfileSettings";
import ProfileCompletion from "../components/ProfilePage/ProfileCompletion";
import UserSkills from "../components/ProfilePage/UserSkills";
import Image from "mui-image";

const Profile = (props) => {
    const [openProfilePicDialog, setOpenProfilePicDialog] = useState(false);
    const handleProfilePicDialog = () => { setOpenProfilePicDialog(!openProfilePicDialog) };

    const isMobile = useScreenResolution('sm');

    return (
        <>
            <Box sx={{
                width: '100%',
                height: { xs: '200px', lg: '300px' },
                position: 'relative',
            }}>
                <Box sx={{
                    width: '100%',
                    height: { xs: '200px', lg: '300px' },
                    position: 'relative',
                }} >
                    <Image
                        src={currentUser.coverPicture}
                        alt='cover-pic'
                        showLoading
                        duration={0}
                    />
                    <EditButton editStyle={{
                        position: 'absolute',
                        bottom: 10,
                        right: '1%',
                        zIndex: 2,
                    }} />
                </Box>

                <Avatar
                    onClick={handleProfilePicDialog}
                    src={currentUser.profilePicture}
                    alt='profile-pic'
                    sx={{
                        width: { xs: '120px', lg: '180px' },
                        height: { xs: '120px', lg: '180px' },
                        position: 'absolute',
                        top: { xs: '150px', lg: '200px' },
                        left: 0,
                        right: 0,
                        margin: 'auto',
                        cursor: 'pointer',
                        transition: 'all 0.5s',
                        '&:hover': {
                            filter: 'grayscale(100%)',
                        }
                    }}
                />
            </Box>

            <Box sx={{
                padding: { xs: '10px 0', md: '20px 70px' },
                margin: { xs: '0 auto 100px', lg: '0 auto' }
            }}>
                <Card sx={{
                    borderRadius: '15px',
                    minHeight: '180px',
                }}>
                    <Stack sx={{
                        padding: '60px 60px 0',
                        justifyContent: 'center'
                    }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            Георги Георгиев
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            ({currentUser.username})
                        </Typography>
                    </Stack>

                    <UserSkills />

                    <Button fullWidth startIcon={<MailOutlineIcon />} variant='contained'>
                        Свържи се
                    </Button>
                </Card>

                {/* <ProfileSettings /> */}

                <ProfileCompletion />

                <ProfileTabs />
            </Box >

            <Dialog
                open={openProfilePicDialog}
                onClose={handleProfilePicDialog}
                fullScreen={isMobile ? true : false}
                fullWidth
            >
                <DialogTitle>
                    <Stack sx={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <Typography variant="h6">
                            Редактирай профилна снимка
                        </Typography>
                        <IconButton onClick={handleProfilePicDialog}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>

                <DialogContent>
                    <ImageUpload profilePic={currentUser.profilePicture} />
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" onClick={handleProfilePicDialog}>Откажи</Button>
                    <Button variant="contained" onClick={handleProfilePicDialog}>Запази промени</Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default function ProfilePage() {
    return (
        <MainContentStructure>
            <Profile />
        </MainContentStructure>
    )
};