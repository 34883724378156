import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { useState } from 'react';

export default function ImageUpload(props) {
    const { profilePic } = props;
    const [uploadedImage, setUploadedImage] = useState(profilePic);

    const getImageUploaded = (event) => {
        if (event.target.files.length === 0) {
            return
        }
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            setUploadedImage(reader.result);
        }
    };

    return (
        <>
            <Avatar
                src={uploadedImage}
                alt='profile-pic'
                sx={{
                    width: '180px',
                    height: '180px',
                    marginBottom: 2,
                }}
            />

            <input
                accept="image/*"
                style={{ display: 'none' }}
                id="upload-profile-image"
                type="file"
                onChange={getImageUploaded}
                name='image'
            />

            <label htmlFor="upload-profile-image" style={{ width: '100%' }}>
                <Button variant="outlined" component="span" fullWidth>
                    Прикачи снимка
                </Button>
            </label>
        </>
    )
};