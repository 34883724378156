import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import useScreenResolution from "../hooks/useScreenResolution";

const ProfileCompletionDialog = (props) => {
    const { openDialog, handleClickDialog } = props;
    const isMobile = useScreenResolution('md');

    return (
        <Dialog
            fullScreen={isMobile ? true : false}
            open={openDialog}
            keepMounted
            aria-describedby="profile-completion-dialog"
        >
            <DialogTitle>
                <Stack sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Typography variant="h6">Попълнени секции</Typography>
                    <IconButton onClick={handleClickDialog}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>

            <DialogContent sx={{
                display: 'grid',
                gridTemplateColumns: '200px 100px',
                gap: 2
            }}>

                <Typography>Профилна снимка</Typography>
                <CheckCircleOutlineIcon sx={{ color: 'green' }} />

                <Typography>Снимка на корица</Typography>
                <HighlightOffIcon sx={{ color: 'red' }} />

                <Typography>Име и фамилия</Typography>
                <HighlightOffIcon sx={{ color: 'red' }} />

                <Typography>Потребителско име</Typography>
                <CheckCircleOutlineIcon sx={{ color: 'green' }} />

                <Typography>Имейл</Typography>
                <CheckCircleOutlineIcon sx={{ color: 'green' }} />

                <Typography>Умения</Typography>
                <CheckCircleOutlineIcon sx={{ color: 'green' }} />
            </DialogContent>

            <DialogActions>
                <Button variant="contained" onClick={handleClickDialog}>Затвори</Button>
            </DialogActions>
        </Dialog>
    )
}

export default function ProfileCompletion(props) {
    const [openDialog, setOpenDialog] = React.useState(false);
    const handleClickDialog = () => { setOpenDialog(!openDialog) };

    return (
        <>
            <Stack
                onClick={handleClickDialog}
                sx={{
                    flexDirection: 'row',
                    gap: 2,
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                <Box sx={{
                    position: 'relative',
                    display: 'inline-flex',
                    marginTop: 2
                }}>
                    <CircularProgress variant="determinate" value={60} size={60} />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="caption" component="div" color="text.secondary">
                            60%
                        </Typography>
                    </Box>
                </Box>
                <Typography>
                    Попълнен профил
                </Typography>

            </Stack>
            <ProfileCompletionDialog
                openDialog={openDialog}
                handleClickDialog={handleClickDialog}
            />
        </>
    )
}