import * as React from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from '@mui/icons-material/Add';
import currentUser from "../db-files/currentUser";
import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import useScreenResolution from "../hooks/useScreenResolution";
import CloseIcon from '@mui/icons-material/Close';

const AddSkill = (props) => {
    const { openDialog, handleClickDialog } = props;
    const isMobile = useScreenResolution('md');

    const [inputSkillValue, setInputSkillValue] = React.useState('');
    const handleInputSkillChange = (event) => { setInputSkillValue(event.target.value) }

    const [skills, setSkills] = React.useState([]);

    const handleSkillChange = () => {
        if (inputSkillValue === '' || skills.includes(inputSkillValue)) { return };

        setSkills([...skills, inputSkillValue]);
        setInputSkillValue('');
    };

    const handleDeleteSkill = (curentSkill) => {
        setSkills(skills.filter(skill => skill !== curentSkill))
    };

    return (
        <Dialog
            fullScreen={isMobile ? true : false}
            open={openDialog}
            keepMounted
            aria-describedby="profile-completion-dialog"
        >
            <DialogTitle>
                <Stack sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Typography variant="h6">Добави твоите умения</Typography>
                    <IconButton onClick={handleClickDialog}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <Grid item xs={12}>
                    <TextField fullWidth sx={{ margin: '0 0 24px' }}
                        value={inputSkillValue}
                        onChange={handleInputSkillChange} />
                    <Button
                        variant='contained'
                        sx={{ margin: '0 0 16px' }}
                        onClick={handleSkillChange}
                    >
                        + Добави умение
                    </Button>

                    <Stack direction="row" spacing={1}>
                        {
                            skills.map((skill) => (
                                <Chip
                                    label={skill}
                                    onDelete={() => handleDeleteSkill(skill)}
                                    key={skill}
                                />
                            ))
                        }
                    </Stack>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={handleClickDialog}>Затвори</Button>
                <Button variant="contained" onClick={handleClickDialog}>Добави</Button>
            </DialogActions>
        </Dialog>
    )
};

export default function UserSkills(props) {
    const [openDialog, setOpenDialog] = useState(false);
    const handleClickDialog = () => { setOpenDialog(!openDialog) };

    return (
        <>
            <Grid container sx={{
                justifyContent: 'center',
                gap: 2,
                margin: '24px auto',
                maxWidth: '80%'
            }}>
                {
                    currentUser.skills.map((skill, indx) => {
                        return (
                            <Grid item key={indx}>
                                <Chip label={skill} variant="filled" sx={{
                                    '&:hover': { backgroundColor: '#bdbdbd' }
                                }} />
                            </Grid>
                        )
                    })
                }
                <Chip
                    label="Добави умения"
                    // onClick={handleClick}
                    icon={<AddIcon />}
                    color='info'
                    onClick={handleClickDialog}
                />
            </Grid>

            <AddSkill openDialog={openDialog} handleClickDialog={handleClickDialog} />
        </>
    );
};